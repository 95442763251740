module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/layouts/index.js"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BenWiley - Personal Website","short_name":"BenWiley","start_url":"/index.html","background_color":"white","theme_color":"#666","display":"standalone","icons":[{"src":"/icons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/icons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/icons/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/apple-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/icons/apple-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/icons/apple-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/apple-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/icons/apple-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/icons/favicon-96x96.png","sizes":"144x144","type":"image/png"},{"src":"/icons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/icons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icons/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/ms-icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/icons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
